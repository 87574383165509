/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create CurrencyContext
const CurrencyContext = createContext();

// Provide CurrencyContext
export const CurrencyProvider = ({ children }) => {
  const [exchangeRates, setExchangeRates] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState(''); // Default currency is USD

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          // 'https://api.apilayer.com/currency_data/live?source=  source&currencies=currencies'
          // 'https://api.exchangerate.host/live?access_key=b4cd4a15cbd227ec2e1a678a3dc664bacurrencies=EUR,USD,SAR,JOD&source=USD&format=1'
          // 'https://api.exchangerate.host/live?access_key=b4cd4a15cbd227ec2e1a678a3dc664ba&currencies=EUR,USD,SAR,JOD&source=USD&format=1'
          // `https://api.exchangerate.host/live?access_key=b4cd4a15cb d227ec2e1a678a3dc664ba&currencies=EUR,USD,SAR,JOD&source=USD&format=1`
        );
        const data = await response?.json();
console.log(data.success,'datadata')
        if (data.success) {
          setExchangeRates(data?.quotes); // Save exchange rates
        } else {
          console?.log('API Error:', data?.error?.info);
        }
      } catch (error) {
        console?.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  // Context value
  const contextValue = {
    exchangeRates,
    selectedCurrency,
    setSelectedCurrency,
  };

  return <CurrencyContext.Provider value={contextValue}>{children}</CurrencyContext.Provider>;
};

// Custom hook to use CurrencyContext
export const useCurrency = () => {
  return useContext(CurrencyContext);
};
