import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import login from '../assets/favicon.png';
import weBook from '../assets/web_book.png';
import login from '../assets/login.png';
import Image from './Image';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
    const theme = useTheme();
    const themeDarkLight = theme?.palette?.mode;
  const logo = (
    <Box sx={{ width: 180, ...sx ,margin:'auto'}}>
      <Image visibleByDefault disabledEffect alt="login" src={ themeDarkLight === 'dark' ? weBook: login } />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
