import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

export default function useOffSetTop(top) {
  const [offsetTop, setOffSetTop] = useState(false);
  const isTop = top || 100;
  const { pathname } = useLocation(); 

  useEffect(() => {
    if(pathname?.split("/")[1]?.includes('buying')){
      setOffSetTop(true);
    }else{
      setOffSetTop(false);
    }

    window.onscroll = () => {
      if (window.pageYOffset > isTop || pathname?.split("/")[1]?.includes('buying')) {
        setOffSetTop(true);
      } else {
        setOffSetTop(false);
      }
    };
    return () => {
      window.onscroll = null;
    };
  }, [isTop , pathname]);

  return offsetTop;
}

// Usage
// const offset = useOffSetTop(100);
