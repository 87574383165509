/* eslint-disable react/prop-types */
import { createContext, useState } from "react";


export const  modalCreate = createContext();


export default function  ModalProvider({children}){
     const [payPalConnect, setPaypalConnect] = useState(false);

     return (
        <modalCreate.Provider value={{payPalConnect , setPaypalConnect}}>
            {children}
        </modalCreate.Provider>
     )
}


