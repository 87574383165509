import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import BorderClearIcon from '@mui/icons-material/BorderClear';
// import { googleLogout } from '@react-oauth/google';
import LogoutIcon from '@mui/icons-material/Logout';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, Stack } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

export default function AccountPopover() {
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  const {
    //  logout, 
    user } = useAuth();

  const currentUser = JSON.parse(localStorage.getItem('currentuser'));

  // const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout =  () => {
    // try {
    //   await logout();
    //   localStorage.removeItem('currentuser');
    //   navigate(currentUser?.role_id === 1 ? '/admin/login' : '/admin/login', { replace: true });
    //   googleLogout();
    //   if (isMountedRef.current) {
    //     handleClose();
    //   }
    //   enqueueSnackbar('Logout Successfully..!', { variant: 'success' });
    // } catch (error) {
    //   console.error(error);
    //   // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    // }
    localStorage.clear();
    enqueueSnackbar('Logout Successfully..!', { variant: 'success' });
    navigate('/')
  };

  return (
    <>
      {currentUser?.role_id === 3 ? (
        <Box>
          <IconButtonAnimate
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            {token ? (
              <Avatar
                alt="Profile Image"
                src={`http://webook-ticket.devssh.xyz${currentUser?.image || user?.image}`}
                sx={{ width: 40, height: 40 }}
              />
            ) : (
              <Avatar sx={{ width: 40, height: 40 }}>MR</Avatar>
            )}
          </IconButtonAnimate>

          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: '230px',
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            <Stack sx={{ p: 2, width: 220 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Avatar
                  alt="Profile Image"
                  src={`http://webook-ticket.devssh.xyz${currentUser?.image || user?.image}`}
                  sx={{ width: 40, height: 40 }}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" noWrap>
                    {currentUser?.first_name || 'Miqdad Raza'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    <Link to={'/dashboard/edituser'}>View Profile</Link>
                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />

              <MenuItem onClick={() => navigate('/dashboard/order')}>
                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                    <BorderClearIcon />
                  </Box>
                  My Orders
                </Box>
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleLogout} sx={{ bgcolor: alpha('#FFC0CB', 0.3) }}>
                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                    <LogoutIcon />
                  </Box>
                  Logout
                </Box>
              </MenuItem>
            </Stack>
          </MenuPopover>
        </Box>
      ) : (
        <Box>
          <IconButtonAnimate
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <MyAvatar />
          </IconButtonAnimate>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {currentUser?.first_name || user?.first_name}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {currentUser?.email || user?.email}
              </Typography>
            </Box>

            {currentUser?.role !== 'admin' && (
              <>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Divider sx={{ borderStyle: 'dashed' }} />
              </>
            )}

            {
              currentUser?.role_id === 1 ? (
                <MenuItem
                  sx={{ m: 1 }}
                  onClick={() => navigate('/dashboard/editadmin')}
                >
                  Edit Profile
                </MenuItem>
              ) : (
                <></>
              )
            }
            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </MenuPopover>
        </Box >
      )
      }
    </>
  );
}
