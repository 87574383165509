import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/admin/login', element: <Login /> },
    { path: '/user/register', element: <RegisterUser /> },
    { path: '/user/login', element: <LoginUser /> },
    { path: '/user/reset-password', element: <ResetPassword /> },
    { path: '/user/verifytoken', element: <VerifyToken /> },
    { path: '/verify', element: <TokenVerifyLogin /> },
    { path: '/user/forgetpassmail', element: <ForgetPassEmail /> },
    { path: '/user/change-password', element: <ChangePassword /> },
    // Admin Dashboard Routes Start
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'user', element: <User /> },
        { path: 'adduser', element: <AddUser /> },
        { path: 'edituser/:id', element: <EditUser /> },
        { path: 'subadmin', element: <SubAdmin /> },
        { path: 'addsubadmin', element: <AddSubAdmin /> },
        { path: 'editsubadmin/:id', element: <EditSubAdmin /> },
        { path: 'subadminpermission', element: <SubAdminPermission /> },
        { path: 'addsubadminpermission', element: <AddSubAdminPermission /> },
        { path: 'editsubadminpermission/:id', element: <EditSubAdminPermission /> },
        { path: 'category', element: <Category /> },
        { path: 'commissionform', element: <CommissionForm /> },
        { path: 'addcategory', element: <AddCategory /> },
        { path: 'editcategory/:id', element: <EditCategory /> },
        { path: 'editadmin', element: <EditAdmin /> },
        { path: 'league', element: <League /> },
        { path: 'addleague', element: <AddLeague /> },
        { path: 'editleague/:id', element: <EditLeague /> },
        { path: 'leagueteam', element: <LeagueTeam /> },
        { path: 'addleagueteam', element: <AddLeagueTeam /> },
        { path: 'editleagueteam/:id', element: <EditLeagueTeam /> },
        { path: 'team', element: <Team /> },
        { path: 'admin_sold_ticket', element: <AdminSoldTicket /> },
        { path: 'admin_total_ticket', element: <AdminTotalTicket /> },
        { path: 'addteam', element: <AddTeam /> },
        { path: 'editteam/:id', element: <EditTeam /> },
        { path: 'matches', element: <Matches /> },
        { path: 'addmatches', element: <AddMatches /> },
        { path: 'addmatcheresult/:id', element: <AddMatcheResult /> },
        { path: 'editmatches/:id', element: <EditMatches /> },
        { path: 'matcheticket', element: <MatcheTicket /> },
        { path: 'addmatcheticket', element: <AddMatcheTicket /> },
        { path: 'editmatcheticket/:id', element: <EditMatcheTicket /> },
        { path: 'buyerandseller', element: <BuyerAndSeller /> },
      ],
    },
    // Admin Dashboard Routes End

    // User Routes Start
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'tickets', element: <Tickets /> },
        { path: 'wallet', element: <Wallet /> },
        { path: 'addtickets', element: <AddTickets /> },
        { path: 'edittickets/:id', element: <EditTicket /> },
        { path: 'order', element: <Order /> },
        { path: 'bankinfo', element: <BankInfo /> },
        { path: 'about', element: <About /> },
        { path: 'contact', element: <Contact /> },
        { path: 'profile', element: <UserProfile /> },
        { path: 'termsandconditions', element: <TermsAndConditions /> },
        { path: 'refundpolicy', element: <RefundPolicy /> },
        { path: 'soldticket/:id', element: <SoldTickets /> },
        { path: 'ticketdetail/:id', element: <TicketDetailPage /> },
        { path: 'allleagues', element: <AllLeagues /> },
        { path: 'allmatches', element: <AllMatch /> },
        { element: <HomePage />, index: true },
        { path: 'buying/:id', element: <Buying /> },
        { path: 'teamss', element: <Teamss /> },
        { path: 'checkout/:id', element: <Checkout /> },
        { path: 'soldmatch', element: <SoldMatch /> },
        { path: 'allmatches/:id', element: <AllMatcheSingle /> },
      ],
    },
    // User Routes End
  ]);
}

// AUTHENTICATION
const MainLayout = Loadable(lazy(() => import('../layouts/main')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const RegisterUser = Loadable(lazy(() => import('../pages/auth/UserRegister')));
const LoginUser = Loadable(lazy(() => import('../pages/auth/LoginUser')));
const ForgetPassEmail = Loadable(lazy(() => import('../pages/auth/ForgetPassEmail')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));
const VerifyToken = Loadable(lazy(() => import('../pages/auth/VerifyToken')));
const TokenVerifyLogin = Loadable(lazy(() => import('../pages/auth/TokenVerifyLogin')));
const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));
const User = Loadable(lazy(() => import('../pages/dashboard/User/User')));
const AddUser = Loadable(lazy(() => import('../pages/dashboard/User/AddUser')));
const EditUser = Loadable(lazy(() => import('../pages/dashboard/User/EditUser')));
const SubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/SubAdminPermission')));
const AddSubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/AddSubAdminPermission')));
const EditSubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/EditSubAdminPermission')));
const SubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/SubAdmin')));
const AddSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/AddSubAdmin')));
const EditSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/EditSubAdmin')));
const League = Loadable(lazy(() => import('../pages/dashboard/League/League')));
const AddLeague = Loadable(lazy(() => import('../pages/dashboard/League/AddLeague')));
const EditLeague = Loadable(lazy(() => import('../pages/dashboard/League/EditLeague')));
const Team = Loadable(lazy(() => import('../pages/dashboard/Team/Team')));
const AddTeam = Loadable(lazy(() => import('../pages/dashboard/Team/AddTeam')));
const EditTeam = Loadable(lazy(() => import('../pages/dashboard/Team/EditTeam')));
const LeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/LeagueTeam')));
const AddLeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/AddLeagueTeam')));
const EditLeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/EditLeagueTeam')));
const Matches = Loadable(lazy(() => import('../pages/dashboard/Matches/Matches')));
const AddMatcheResult = Loadable(lazy(() => import('../pages/dashboard/Matches/AddMatchResult')));
const AddMatches = Loadable(lazy(() => import('../pages/dashboard/Matches/AddMatches')));
const EditMatches = Loadable(lazy(() => import('../pages/dashboard/Matches/EditMatches')));
const MatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/MatcheTicket')));
const Wallet = Loadable(lazy(() => import('../pages/dashboard/Wallet/Wallet')));
const AddMatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/AddMatcheTicket')));
const EditMatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/EditMatcheTicket')));
const BuyerAndSeller = Loadable(lazy(() => import('../pages/dashboard/BuyerAndSeller/BuyerAndSeller')));
const CommissionForm = Loadable(lazy(() => import('../pages/dashboard/CommissionForm/CommissionForm')));
const Category = Loadable(lazy(() => import('../pages/dashboard/Category/Category')));
const AddCategory = Loadable(lazy(() => import('../pages/dashboard/Category/AddCategory')));
const EditCategory = Loadable(lazy(() => import('../pages/dashboard/Category/EditCategory')));
// const ProfilePage = Loadable(lazy(() => import('../pages/dashboard/Profile/ProfilePage')));

const Tickets = Loadable(lazy(() => import('../pages/dashboard/Ticket/Ticket')));
const Order = Loadable(lazy(() => import('../pages/dashboard/Order/Order')));
const BankInfo = Loadable(lazy(() => import('../pages/dashboard/BankInfo/BankInfo')));
const AddTickets = Loadable(lazy(() => import('../pages/dashboard/Ticket/AddTicket')));
const AdminSoldTicket = Loadable(lazy(() => import('../pages/dashboard/Ticket/AdminSoldTicket')));
const AdminTotalTicket = Loadable(lazy(() => import('../pages/dashboard/Ticket/AdminTotalTicket')));
const EditTicket = Loadable(lazy(() => import('../pages/dashboard/Ticket/EditTicket')));
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile/UserProfile')));
const TicketDetailPage = Loadable(lazy(() => import('../pages/dashboard/TicketDetailPage')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const About = Loadable(lazy(() => import('../pages/About')));
const TermsAndConditions = Loadable(lazy(() => import('../sections/term/TermsAndConditions')));
const RefundPolicy = Loadable(lazy(() => import('../sections/term/RefundPolicy')));
const SoldTickets = Loadable(lazy(() => import('../sections/home/SoldTickets')));
const AllLeagues = Loadable(lazy(() => import('../sections/home/AllLeague')));
const AllMatch = Loadable(lazy(() => import('../sections/home/AllMatch')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Buying = Loadable(lazy(() => import('../pages/Buying')));
const Teamss = Loadable(lazy(() => import('../pages/Team')));
const Checkout = Loadable(lazy(() => import('../pages/Checkout')));
const AllMatcheSingle = Loadable(lazy(() => import('../sections/home/AllMatcheSingle')));
const SoldMatch = Loadable(lazy(() => import('../sections/home/SoldMatch')));
